<template>
  <div
    class="v_shopping_mail_pages_custom_list_modules_title_bar_unit bbox d-flex align-center"
  >
    <div class="name cp">湖北轻工职业技术学院</div>

    <el-popover
      placement="right-start"
      v-model="visible"
      popper-class="v_shopping_mail_pages_custom_list_modules_title_bar_unit_popover"
      :visible-arrow="false"
    >
      <div class="menu_list">
        <div class="item">重命名清单</div>
        <div class="item">分享清单</div>
        <div class="item">推广清单</div>
        <div class="item">删除清单</div>
      </div>
      <div
        class="more cp mt-1"
        slot="reference"
      ></div>
    </el-popover>
  </div>
</template>

<script>
export default {
  data(){
    return{
      visible: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.v_shopping_mail_pages_custom_list_modules_title_bar_unit{
  &:hover{
    .more{
      opacity: 1;
    }
  }
  .more{
    @include transition;
    @include background(3px);
    opacity         : 0;
    width           : 20px;
    height          : 20px;
    background-image: url(~@/assets/images/shopping_mail/custom_list/more.png);
    &.active{
      opacity: 1;
    }
  }
}
</style>
<style lang="scss">
.v_shopping_mail_pages_custom_list_modules_title_bar_unit_popover{
  margin-left: 0!important;
  min-width  : 10px;
  padding    : 5px 0;
  .menu_list{
    .item{
      @include transition;
      box-sizing : border-box;
      width      : 120px;
      font-size  : 14px;
      color      : #333;
      line-height: 34px;
      cursor     : pointer;
      padding: {
        left: 16px;
      }
      &:hover{
        background-color: #eee;
      }
    }
  }
}
</style>
