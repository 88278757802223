var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_shopping_mail_pages_custom_list_modules_title_bar_unit bbox d-flex align-center",
    },
    [
      _c("div", { staticClass: "name cp" }, [_vm._v("湖北轻工职业技术学院")]),
      _c(
        "el-popover",
        {
          attrs: {
            placement: "right-start",
            "popper-class":
              "v_shopping_mail_pages_custom_list_modules_title_bar_unit_popover",
            "visible-arrow": false,
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "menu_list" }, [
            _c("div", { staticClass: "item" }, [_vm._v("重命名清单")]),
            _c("div", { staticClass: "item" }, [_vm._v("分享清单")]),
            _c("div", { staticClass: "item" }, [_vm._v("推广清单")]),
            _c("div", { staticClass: "item" }, [_vm._v("删除清单")]),
          ]),
          _c("div", {
            staticClass: "more cp mt-1",
            attrs: { slot: "reference" },
            slot: "reference",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }