<template>
  <el-table
    :data="tableData"
    :header-cell-style="{
      'font-size': '14px',
      'font-weight': 'normal',
      color: '#999',
    }"
    :cell-style="{
      'font-size': '14px',
      color: '#666',
      padding: '5px 0',
    }"
    :show-header="!sold_out"
    stripe
    height="100%"
    max-height="100%"
    class="v_shopping_mail_pages_custom_list_modules_list_table"
    :class="{sold_out}"
  >
    <el-table-column
      v-if="!sold_out"
      align="center"
      header-align="center"
      type="selection"
      width="50"
    >
    </el-table-column>
    <el-table-column
      fixed
      prop="name"
      label="名称"
      width="400"
    >
      <template
        slot-scope="scope"
      >
        <div
          class="cell_name"
        >{{scope.row.name}}</div>
      </template>
    </el-table-column>
    <el-table-column
      prop="createuserthumbnail"
      label="讲师"
      width="120"
    >
      <template slot-scope="scope">
        <el-tooltip
          class="item"
          effect="dark"
          :content="scope.row.createusername"
          placement="right"
        >
          <img
            :src="`${$avatar_url}${scope.row.createuserthumbnail}@!small200`"
            alt=""
            class="lecturer_avatar"
          >
        </el-tooltip>
      </template>
    </el-table-column>
    <template
      v-if="!sold_out"
    >
      <el-table-column
        prop="province"
        label="上架时间"
        width="120">
      </el-table-column>
      <el-table-column
        prop="sale"
        label="销量"
        width="120">
      </el-table-column>
      <el-table-column
        prop="readamount"
        label="浏览量"
        width="300">
      </el-table-column>
      <el-table-column
        prop="price"
        label="售价"
      >
      </el-table-column>
      <el-table-column
        prop="category"
        v-if="0"
        label="分类"
      >
      </el-table-column>
    </template>
    <template
      v-else
    >
      <el-table-column
        prop="empty"
        label="空"
      >
        <template
          slot-scope="scope"
        >
          <div
            class="empty_group"
          >—</div>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
export default {
  props: {
    sold_out: { // 是否下架模式
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      tableData: [
        {
          name: 'A00002-观影《大腕》A00002-观影《大腕》A00002-观影《大腕》A00002-观影《大腕》A00002-观影《大腕》A00002-观影《大腕》A00002-观影《大腕》A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类',
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },   {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
           {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
               {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
               {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
               {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
               {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
               {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
               {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },       {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
               {
          name: 'A00002-观影《大腕》',
          createusername: '小断',
          createuserthumbnail: '2016/08/08/1470621514148489.jpg',
          createTime: 1637730503000,
          sale: 3,
          readamount: 44,
          price: 8000,
          category: '默认分类'
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.v_shopping_mail_pages_custom_list_modules_list_table{
  .lecturer_avatar{
    width: 30px;
    height: 30px;
  }
  .cell_name{
    color: #333;
  }
  &.sold_out{
    opacity: 0.5;
  }
}
</style>

