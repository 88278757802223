var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "v_shopping_mail_pages_custom_list_operation_bar bbox d-flex justify-space-between",
      },
      [
        _c("div", { staticClass: "info_group d-flex align-center" }, [
          _c("div", { staticClass: "price_group d-flex align-center" }, [
            _c("div", { staticClass: "text pr-5" }, [_vm._v("总价:")]),
            _c("div", { staticClass: "price" }, [_vm._v("¥ 5000.00")]),
          ]),
          _c("div", { staticClass: "count_group d-flex align-center ml-20" }, [
            _c("div", { staticClass: "text pr-5" }, [_vm._v("包含:")]),
            _c("div", { staticClass: "count pr-5" }, [_vm._v("10")]),
            _c("div", { staticClass: "text" }, [_vm._v("个")]),
          ]),
        ]),
        _c("div", { staticClass: "operation_group d-flex align-center" }, [
          _c("div", { staticClass: "btn btn_light button clear" }, [
            _vm._v("清空已选"),
          ]),
          _c("div", { staticClass: "btn btn_dark button ml-10 sure" }, [
            _vm._v("确认"),
          ]),
          _c("div", { staticClass: "btn btn_dark button ml-10 submit" }, [
            _vm._v("结算"),
          ]),
          _c("div", { staticClass: "btn button ml-10 delete" }, [
            _vm._v("全部移除"),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }