var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_shopping_mail_pages_custom_list_modules_sold_out_table bbox d-flex flex-column align-center",
    },
    [
      _vm._m(0),
      _c("list-table", {
        staticClass: "list_table_component mt-20",
        attrs: { sold_out: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "caption_group d-flex un_sel" }, [
      _c("div", { staticClass: "caption pr-20" }, [_vm._v("已下架 2 个")]),
      _c("div", { staticClass: "button btn cp" }, [_vm._v("清空下架模板")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }