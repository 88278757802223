<template>
  <div
    class="views_shopping_mail_modules_title_search bbox d-flex align-center"
  >
    <img
      src="~@/assets/images/shopping_mail/search_back.png"
      alt=""
      class="icon mr-20 cp"
      @click.stop="$emit('handleReturn')"
    >
    <div class="title">{{title}}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_title_search{
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  line-height: 40px;
  border-bottom: 1px solid #f4f4f4;
}
</style>
