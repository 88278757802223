<template>
  <div
    class="v_shopping_mail_pages_custom_list_modules_sold_out_table bbox d-flex flex-column align-center"
  >
    <div class="caption_group d-flex un_sel">
      <div class="caption pr-20">已下架 2 个</div>
      <div class="button btn cp">清空下架模板</div>
    </div>
    <list-table
      class="list_table_component mt-20"
      sold_out
    ></list-table>
  </div>
</template>

<script>
import ListTable from './ListTable.vue';
export default {
  components: {
    ListTable,
  }
}
</script>

<style lang="scss" scoped>
.v_shopping_mail_pages_custom_list_modules_sold_out_table{
  width: 100%;
  .caption_group{
    width: 100%;
    font-size: 16px;
    color: #333;
    .btn{
      color: $main_blue;
      font-size: 14px;
    }
  }
}
</style>
