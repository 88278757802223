var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      staticClass: "v_shopping_mail_pages_custom_list_modules_list_table",
      class: { sold_out: _vm.sold_out },
      attrs: {
        data: _vm.tableData,
        "header-cell-style": {
          "font-size": "14px",
          "font-weight": "normal",
          color: "#999",
        },
        "cell-style": {
          "font-size": "14px",
          color: "#666",
          padding: "5px 0",
        },
        "show-header": !_vm.sold_out,
        stripe: "",
        height: "100%",
        "max-height": "100%",
      },
    },
    [
      !_vm.sold_out
        ? _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              type: "selection",
              width: "50",
            },
          })
        : _vm._e(),
      _c("el-table-column", {
        attrs: { fixed: "", prop: "name", label: "名称", width: "400" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("div", { staticClass: "cell_name" }, [
                  _vm._v(_vm._s(scope.row.name)),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "createuserthumbnail", label: "讲师", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: scope.row.createusername,
                      placement: "right",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "lecturer_avatar",
                      attrs: {
                        src: `${_vm.$avatar_url}${scope.row.createuserthumbnail}@!small200`,
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      !_vm.sold_out
        ? [
            _c("el-table-column", {
              attrs: { prop: "province", label: "上架时间", width: "120" },
            }),
            _c("el-table-column", {
              attrs: { prop: "sale", label: "销量", width: "120" },
            }),
            _c("el-table-column", {
              attrs: { prop: "readamount", label: "浏览量", width: "300" },
            }),
            _c("el-table-column", { attrs: { prop: "price", label: "售价" } }),
            0
              ? _c("el-table-column", {
                  attrs: { prop: "category", label: "分类" },
                })
              : _vm._e(),
          ]
        : [
            _c("el-table-column", {
              attrs: { prop: "empty", label: "空" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "empty_group" }, [_vm._v("—")]),
                    ]
                  },
                },
              ]),
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }