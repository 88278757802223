<template>
  <div
    class="v_shopping_mail_pages_custom_list d-flex flex-column"
  >
    <page-title
      class="pa-20 page_title flex-shrink-0"
      title="定制清单"
      @handleReturn="handleReturn"
    ></page-title>
    <div class="main_group d-flex flex-column justify-space-between">
      <title-bar
        class="title_bar bbox px-20 flex-shrink-0"
      ></title-bar>
      <div class="custom_list_section d-flex flex-column pb-20">
        <list-operation
          class="list_operation_component px-20 py-16 flex-shrink-0"
        ></list-operation>
        <div
          class="table_group bbox d-flex flex-column bbox px-20"
        >

          <list-table
            class="list_table_component"
          ></list-table>
          <sold-out-table
            class="sold_out_table_component flex-shrink-0 pt-20"
          ></sold-out-table>
        </div>
      </div>
      <operation-bar
        class="operation_bar flex-shrink-0"
      ></operation-bar>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/views/ShoppingMail/Modules/Title/Common.vue';
import TitleBar from './TitleBar.vue';
import OperationBar from './OperationBar.vue';
import ListOperation from './Modules/ListOperation.vue';
import ListTable from './Modules/ListTable.vue';
import SoldOutTable from './Modules/SoldOutTable.vue';
export default {
  components: {
    PageTitle,
    TitleBar,
    OperationBar,
    ListOperation,
    ListTable,
    SoldOutTable,
  },
  methods: {
    handleReturn(){
      const {prev_route} = this.$store.state;
      const {name, params} = prev_route;
      // 恢复路由
      this.$router.push({name, params});
    }
  }
}
</script>

<style lang="scss" scoped>
.v_shopping_mail_pages_custom_list{
  background-color: #f4f4f4;
  width           : 100%;
  height          : 100%;
  overflow-y: auto;
  .page_title{
    background-color: #fff;
    width           : 100%;
  }
  .main_group{
    @include scrollbar;
    overflow-y: auto;
    width     : 100%;
    flex      : 1;
    position  : relative;
    // background-color: #f4f4f4;
    .create_group{
      width        : 245px;
      height       : 100px;
      border       : 1px dashed #C6D0D6;
      border-radius: 4px;
      font-size    : 22px;
      color        : #88A0B5;
      margin       : {
        left: 20px;
        top : 20px;
      }
      .icon{
        margin-right: 10px;
      }
    }
  }
  .title_bar{
    background-color: #fff;
    height          : 40px;
    overflow        : hidden;
  }
  .operation_bar{
    height  : 70px;
    padding : 0 40px;
  }
  .custom_list_section{
    width: 100%;
    flex: 1;
    overflow-y: auto;
    position: relative;
    .list_operation_component{
      // position: sticky;
      // top: 0;
      // left: 0;
      background-color: #f4f4f4;
      width           : 100%;
      // z-index: 3;
    }
    .table_group{
      // flex: 1;
      width: 100%;
      overflow: auto;
    }
    .list_table_component{
      position: relative;
      z-index: 2;
    }
    .sold_out_table_component{
      max-height: 260px;
    }
  }
}
</style>
