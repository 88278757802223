var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_shopping_mail_pages_custom_list_title_bar d-flex justify-space-between align-center",
    },
    [
      _c("div", { staticClass: "list_group d-flex align-center" }, [
        _c(
          "div",
          { staticClass: "scroll_group d-flex align-center" },
          [
            _vm._m(0),
            _c("list-unit", { staticClass: "list" }),
            _vm._m(1),
            _vm._m(2),
            _vm._m(3),
            _vm._m(4),
            _vm._m(5),
            _vm._m(6),
            _vm._m(7),
            _vm._m(8),
            _vm._m(9),
          ],
          1
        ),
        _vm._m(10),
      ]),
      _vm._m(11),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list active" }, [
      _c("div", { staticClass: "name" }, [_vm._v("未命名")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("湖北轻工职业技术学院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("湖北轻工职业技术学院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("湖北轻工职业技术学院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("湖北轻工职业技术学院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("湖北轻工职业技术学院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("湖北轻工职业技术学院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("湖北轻工职业技术学院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("湖北轻工职业技术学院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("湖北轻工职业技术学院")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "create_group d-flex align-center flex-shrink-0 pr-20 un_sel cp",
      },
      [
        _c("img", {
          staticClass: "gradient",
          attrs: {
            src: require("@/assets/images/shopping_mail/custom_list/gradient.png"),
            alt: "",
          },
        }),
        _c("img", {
          staticClass: "icon mr-4",
          attrs: {
            src: require("@/assets/images/shopping_mail/custom_list/create.png"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "text" }, [_vm._v("创建清单")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "all_group flex-shrink-0 cp" }, [
      _c("div", { staticClass: "text" }, [_vm._v("全部>")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }