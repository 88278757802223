<template>
  <div
    class="v_shopping_mail_pages_custom_list_modules_list_operation bbox d-flex align-center justify-start"
  >
    <div class="button btn mr-10">添加模板</div>
    <div class="button btn mr-10">多选</div>
    <div class="search_group d-flex align-center cp">
      <img src="~@/assets/images/shopping_mail/custom_list/search.png" alt="" class="icon"
        @click="$refs.search.focus()"
      >
      <input type="text" class="search bbox cp"
        :class="{active: keyword !== ''}"
        ref="search"
        v-model="keyword"
        placeholder="输入内容，Enter进行搜索"
      >
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      keyword: '',
    }
  }
}
</script>

<style lang="scss" scoped>
.v_shopping_mail_pages_custom_list_modules_list_operation{
  .btn{
    border          : 1px solid #ddd;
    border-radius   : 4px;
    font-size       : 14px;
    line-height     : 28px;
    padding         : 0 16px;
    background-color: #fff;
    color           : #666;
  }
  .search_group{
    position: relative;
    .icon{
      position : absolute;
      top      : 50%;
      left     : 12px;
      transform: translateY(-50%);
    }
    .search{
      @include transition;
      @include placeholder(#ccc);
      width           : 40px;
      border          : 1px solid #ddd;
      height          : 30px;
      color           : #666;
      background-color: #fff;
      border-radius   : 4px;
      font-size       : 14px;
      line-height     : 28px;
      padding-left    : 40px;
      outline         : none;
      &:focus, &.active{
        width        : 260px;
        border-color : $main_blue;
        padding-right: 10px;
      }
    }
  }
}
</style>
