<template>
  <div
    class="v_shopping_mail_pages_custom_list_title_bar d-flex justify-space-between align-center"
  >
    <div class="list_group d-flex align-center">
      <div class="scroll_group d-flex align-center">

        <div class="list active">
          <div class="name">未命名</div>
        </div>
        <list-unit
          class="list"
        ></list-unit>
        <div class="list">
          <div class="name">湖北轻工职业技术学院</div>
        </div>
        <div class="list">
          <div class="name">湖北轻工职业技术学院</div>
        </div>
        <div class="list">
          <div class="name">湖北轻工职业技术学院</div>
        </div>
        <div class="list">
          <div class="name">湖北轻工职业技术学院</div>
        </div>
        <div class="list">
          <div class="name">湖北轻工职业技术学院</div>
        </div>
        <div class="list">
          <div class="name">湖北轻工职业技术学院</div>
        </div>
        <div class="list">
          <div class="name">湖北轻工职业技术学院</div>
        </div>
        <div class="list">
          <div class="name">湖北轻工职业技术学院</div>
        </div>
        <div class="list">
          <div class="name">湖北轻工职业技术学院</div>
        </div>
      </div>
      <div
        class="create_group d-flex align-center flex-shrink-0 pr-20 un_sel cp"
      >
        <img src="~@/assets/images/shopping_mail/custom_list/gradient.png" alt="" class="gradient">
        <img src="~@/assets/images/shopping_mail/custom_list/create.png" alt="" class="icon mr-4">
        <div class="text">创建清单</div>
      </div>
    </div>
    <div class="all_group flex-shrink-0 cp">
      <div class="text">全部></div>
    </div>
  </div>
</template>

<script>
import ListUnit from './Modules/TitleBarUnit.vue';
export default {
  components: {
    ListUnit
  },
}
</script>

<style lang="scss" scoped>
.v_shopping_mail_pages_custom_list_title_bar{
  width: 100%;
  .list_group{
    @include hidescrollbar;
    position: relative;
    overflow-x: auto;
    .scroll_group{
      @include hidescrollbar;
      flex: 1;
      overflow-x: auto;
      position: relative;
      z-index: 1;
      padding-top: 14px;
      .list{
        @include transition;
        flex-shrink: 0;
        box-sizing: border-box;
        padding: 0 30px;
        position: relative;
        line-height: 40px;
        font-size: 14px;
        color: #333;
        &::after{
          @include transition;
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 3px;
          width: 28px;
        }
        &.active{
          color: $main_blue;
          &::after{
            background-color: $main_blue;
          }
        }
      }
    }
    .create_group{
      position: relative;
      z-index: 2;
      margin-left: -15px;
      font-size: 14px;
      color: #999;
      line-height: 25px;
      .icon{
        width: 15px;
        height: 15px;
      }
    }
  }
  .all_group{
    font-size: 14px;
    color: #999;
  }
}
</style>
