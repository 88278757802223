var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_shopping_mail_pages_custom_list d-flex flex-column" },
    [
      _c("page-title", {
        staticClass: "pa-20 page_title flex-shrink-0",
        attrs: { title: "定制清单" },
        on: { handleReturn: _vm.handleReturn },
      }),
      _c(
        "div",
        { staticClass: "main_group d-flex flex-column justify-space-between" },
        [
          _c("title-bar", {
            staticClass: "title_bar bbox px-20 flex-shrink-0",
          }),
          _c(
            "div",
            { staticClass: "custom_list_section d-flex flex-column pb-20" },
            [
              _c("list-operation", {
                staticClass:
                  "list_operation_component px-20 py-16 flex-shrink-0",
              }),
              _c(
                "div",
                {
                  staticClass: "table_group bbox d-flex flex-column bbox px-20",
                },
                [
                  _c("list-table", { staticClass: "list_table_component" }),
                  _c("sold-out-table", {
                    staticClass: "sold_out_table_component flex-shrink-0 pt-20",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("operation-bar", { staticClass: "operation_bar flex-shrink-0" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }